<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import ActionsData from "./actions-data";
import AddAction from "./add-action";

import {
  layoutComputed,
  authComputed,
  actionsMethods,
  actionsComputed,
} from "@/state/helpers";
/**
 * Actions component
 */
export default {
  page: {
    title: "Actions",
    meta: [{ name: "Actions" }],
  },
  components: {
    Layout,
    PageHeader,
    ActionsData,
    AddAction,
  },
  mounted() {
    this.retrieveActions();
  },
  data() {
    return {
      title: "titles.actions.text",
      busy: false,

      loaderDashboard: false,

      actionsData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...actionsComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.actions.text"),
          active: true,
        },
      ]
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...actionsMethods,

    retrieveActions() {
      if (this.getActions.results) {
        this.actionsData = this.getActions.results;
      } else {
        this.busy = true;
        this.getActionsBy({ limit: 100 }).then(() => {
          this.busy = false;
        });
      }
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-action-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-action-modal");
    },
    add(action) {
      this.addAction(action)
        .then((action) => {
          this.makeToast("Add action", action.name+" has been added", "success")
        })
        .catch(error => {
          this.makeToast("Add action", error, "danger")
        });
    },
  },
  watch: {
    getActions(newVal) {
      this.actionsData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addAction.text")}}
        </b-button>
        <AddAction @addAction="add" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ActionsData v-bind:actions="actionsData" :busy="busy" />
      </div>
    </div>
  </Layout>
</template>